import { type RoutePermissionSetting } from "@sal/onevent-portal";
import { MicrosoftSsoSettings } from "@sal/portal/dist/models/MicrosoftSsoSettings";
import { LoginProps } from "@sal/portal/dist/pages/login/Login";
import { NotificationTypeSystemName } from "./constants";

interface S {
	servicePath: string;
	prefix: string;
	loginProps: LoginProps;
}

const microsoftSsoSettings: MicrosoftSsoSettings = {
	enableSso: true,
	clientID: process.env.MICROSOFT_SSO_SETTINGS_CLIENT_ID!,
	authority: process.env.MICROSOFT_SSO_SETTINGS_AUTHORITY!,
	redirectUrl: process.env.MICROSOFT_SSO_SETTINGS_REDIRECT_URL!,
	scopes: ["user.read", "profile"]
};

const loginProps: LoginProps = {
	microsoftSsoSettings: microsoftSsoSettings
};

// TODO: TO COME FROM CONTROLLER (WHICH GETS IT FROM appsettings.js) AND LOADED ONCE AT APP STARTUP...
export const Settings: S = {
	servicePath: "api/",
	prefix: "SAL",
	loginProps: loginProps
};

// This setting is for the user preference tab. The name of each setting needed to be align with the SystemName of Setting in database.
// TODO: Update the setting when notification added
export const preferenceSettings = [
	// {
	// 	name: NotificationTypeSystemName.ShipmentCreated,
	// 	label: "Confirmation email when self-service booking/shipment is created"
	// },
	// {
	// 	name: NotificationTypeSystemName.ShipmentRequiresApproval,
	// 	label: "Confirmation email when shipment requires approval"
	// },
	// {
	// 	name: NotificationTypeSystemName.ShipmentReadyForDelivery,
	// 	label: "Confirmation email when shipment is ready for delivery"
	// },
	// {
	// 	name: NotificationTypeSystemName.ShipmentArrivedTranshipmentPort,
	// 	label: "Confirmation email when shipment has arrived at transhipment port"
	// },
	// {
	// 	name: NotificationTypeSystemName.ShipmentDepartedTranshipmentPort,
	// 	label: "Confirmation email when shipment has departed transhipment port"
	// },
	// {
	// 	name: NotificationTypeSystemName.ShipmentArrivalWithinDay,
	// 	label: "Confirmation email when shipment is within X days of arrival at final destination"
	// },
	// {
	// 	name: NotificationTypeSystemName.ShipmentOnHold,
	// 	label: "Confirmation email when shipment is on hold"
	// },
	// {
	// 	name: NotificationTypeSystemName.OrderCreated,
	// 	label: "Confirmation email when self-service order is created."
	// },
	// {
	// 	name: NotificationTypeSystemName.OrderInTransit,
	// 	label: "Confirmation email when order In Transit"
	// },
	// {
	// 	name: NotificationTypeSystemName.OrderStatusChanged,
	// 	label: "Confirmation email when order status has changed"
	// },
	// {
	// 	name: NotificationTypeSystemName.OrderMissOriginalReadyDate,
	// 	label: "Confirmation email when order will miss original cargo ready date"
	// },
	// {
	// 	name: NotificationTypeSystemName.ContainerDeliveredToWharf,
	// 	label: "Confirmation email when container has been delivered to the wharf"
	// },
	// {
	// 	name: NotificationTypeSystemName.ContainerCollectedFromWharf,
	// 	label: "Confirmation email when container has been collected from the wharf"
	// },
	// {
	// 	name: NotificationTypeSystemName.ContainerDehired,
	// 	label: "Confirmation email when container has been dehired"
	// },
	// {
	// 	name: NotificationTypeSystemName.ContainerAtRisk,
	// 	label: "Confirmation email when container is at risk of detention, demurrage or storage"
	// },
	{
		name: NotificationTypeSystemName.CustomClearanceConcluded,
		label: "Confirmation email when customs clearance has concluded"
	},
	// {
	// 	name: NotificationTypeSystemName.TransportJobBookingRequested,
	// 	label: "Confirmation email when self-service arrange transport is used."
	// },
	// {
	// 	name: NotificationTypeSystemName.DeliveryBooked,
	// 	label: "Confirmation email when delivery is booked"
	// },
	{
		name: NotificationTypeSystemName.DeliveryOccurred,
		label: "Confirmation email when delivery has occurred"
	},
	// {
	// 	name: NotificationTypeSystemName.DeliveryMissIntoStoreDate,
	// 	label: "Confirmation email when delivery will miss the required delivery/into store date"
	// },
	// {
	// 	name: NotificationTypeSystemName.InvoicePaid,
	// 	label: "Confirmation email when invoice is paid"
	// },
	// {
	// 	name: NotificationTypeSystemName.InvoiceOverdue,
	// 	label: "Confirmation email when invoice is overdue"
	// },
	// {
	// 	name: NotificationTypeSystemName.StatementAvailable,
	// 	label: "Confirmation email when new Statement is available"
	// },
	{
		name: NotificationTypeSystemName.BillOfLading,
		label: "Send notification when a Bill of Lading document is added."
	},
	{
		name: NotificationTypeSystemName.CommercialInvoice,
		label: "Send notification when a Commercial Invoice document is added."
	},
	{
		name: NotificationTypeSystemName.PackingList,
		label: "Send notification when a Packing List document is added."
	},
	{
		name: NotificationTypeSystemName.PackingDeclaration,
		label: "Send notification when a Packing Declaration document is added."
	},
	{
		name: NotificationTypeSystemName.CertificateOfOrigin,
		label: "Send notification when a Certificate of Origin document is added."
	},
	{
		name: NotificationTypeSystemName.CustomsEntryDeclaration,
		label: "Send notification when a Customs Entry / Declaration document is added."
	},
	{
		name: NotificationTypeSystemName.ProofOfDelivery,
		label: "Send notification when a Proof Of Delivery document is added."
	}
];

// This configure setting control the basic setting of preference form
export const preferenceFormConfigSetting = {
	Currency: { hided: false, required: true },
	Home: { hided: true },
	Timezone: { hided: false, required: false },
	Distance: { hided: false, required: true },
	Weight: { hided: false, required: false },
	Size: { hided: false, required: false }
};

export const routePermissionMapping: { [key: string]: RoutePermissionSetting } = {
	track: {
		path: "/Track",
		securityContext: "apI_PortalShipment",
		securityContextPermission: "Read"
	},
	order: {
		path: "/Order",
		securityContext: "apI_PortalOrder",
		securityContextPermission: "Write"
	},
	estimate: {
		path: "/Estimate",
		securityContext: "apI_Estimates",
		securityContextPermission: "Read"
	},
	accounts: {
		path: "/Accounts",
		securityContext: "apI_Accounts",
		securityContextPermission: "Read"
	},
	transport: {
		path: "/Transport",
		securityContext: "apI_PortalTransportJob",
		securityContextPermission: "Read"
	},
	analytics: {
		path: "/Analytics",
		securityContext: "portal_Menu",
		securityContextPermission: "Web_Analytics"
	},
	reports: {
		path: "/Reports",
		securityContext: "portal_Menu",
		securityContextPermission: "Web_Reports"
	},
	schedule: {
		path: "/Schedules",
		permissions: [
			["apI_Flight", "Read"],
			["apI_VesselMovement", "Read"]
		]
	},
	admin: {
		path: "/Admin",
		securityContext: "portal_Menu",
		securityContextPermission: "Web_Admin"
	},
	users: {
		path: "/Users",
		securityContext: "apI_User",
		securityContextPermission: "Read"
	},
	teuCalendar: {
		path: "/TeuCalendar",
		securityContext: "portal_Menu",
		securityContextPermission: "Web_Teu_Calendar"
	}
};
